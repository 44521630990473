html,
body {
	font-family: "Roboto", sans-serif;
	margin: 0px;
	padding: 0px;
	background-color: #f3f3f3;
	height: 100%;
	width: 100%;
}

.body {
	min-height: 92vh;
}

.hero-image {
	position: relative;
	height: 100vh;
}

.hero-image::before {
	content: "";
	background-image: url("img/bg.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	max-width: 100%;
	filter: saturate(0.4) brightness(0.25);
}

.hero-title {
	position: relative;
	height: 100vh;
}

.page-image {
	position: relative;
	height: 10vh;
}

.page-image::before {
	content: "";
	background-image: url("img/bg.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	height: 28vh;
	width: 100vw;
    max-width: 100%;
	filter: saturate(0.4) brightness(0.25);
}

.mapboxgl-popup-content {
	border-radius: 1em;
	padding: 1;
}

.footer {
	background-color: white;
	position: relative;
	z-index: 1;
	left: 0;
	bottom: 0;
	min-height: 8vh;
	box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
}

.anchor-id {
	position: relative;
	top: -100px;
}

.home-card {
	transition: color 300ms;
	transition: background-color 300ms;
}
